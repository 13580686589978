import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Assortment.css';
import logo1 from '../../img/commode.png';
import logo2 from '../../img/bed.png';

export default function Assortment() {
  const { t } = useTranslation();
  const assortmentRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (assortmentRef.current) {
      const rect = assortmentRef.current.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`assortment ${isVisible ? 'fade-in' : ''}`} ref={assortmentRef}>
      <Link to="/furniture" className="block furniture">
        <img src={logo1} alt={t('assortmentFurniture')} />
        <p>{t('assortmentFurniture')}</p>
      </Link>
      <Link to="/bed" className="block bed">
        <img src={logo2} alt={t('assortmentBed')} />
        <p>{t('assortmentBed')}</p>
      </Link>
    </div>
  );
}
