// #region Tumby
import tumba1 from './imgO/tumby/tumba1.jpg';
import tumba2 from './imgO/tumby/tumba2.jpg';
import tumba3 from './imgO/tumby/tumba3.jpg';
import tumba4 from './imgO/tumby/tumba4.jpg';
import tumba5 from './imgO/tumby/tumba5.jpg';
import tumba6 from './imgO/tumby/tumba6.jpg';
import tumba7 from './imgO/tumby/tumba7.jpg';
import tumba8 from './imgO/tumby/tumba8.jpg';
import tumba9 from './imgO/tumby/tumba9.jpg';
import tumba10 from './imgO/tumby/tumba10.jpg';
import tumba11 from './imgO/tumby/tumba11.jpg';
import tumba12 from './imgO/tumby/tumba12.jpg';
import tumba13 from './imgO/tumby/tumba13.jpg';
// #endregion

// #region Komody
import komod1 from './imgO/komody/komod1.jpg';
import komod2 from './imgO/komody/komod2.jpg';
import komod3 from './imgO/komody/komod3.jpg';
import komod4 from './imgO/komody/komod4.jpg';
import komod5 from './imgO/komody/komod5.jpg';
import komod6 from './imgO/komody/komod6.jpg';
import komod7 from './imgO/komody/komod7.jpg';
import komod8 from './imgO/komody/komod8.jpg';
import komod9 from './imgO/komody/komod9.jpg';
import komod10 from './imgO/komody/komod10.jpg';
// #endregion

// #region Shafy
import shafa1 from './imgO/shafy/shafa1.jpg';
import shafa2 from './imgO/shafy/shafa2.jpg';
import shafa3 from './imgO/shafy/shafa3.jpg';
// #endregion

import './Products.css';

const orleanProducts = [
  {
    id: 41,
    name: 'Тумба приліжкова "Орлеан"',
    //price: 'вiд 5 250₴',
    img: tumba1,
    additionalImages: [tumba2, tumba3, tumba4, tumba5, tumba6, tumba7, tumba8, tumba9, tumba10, tumba11, tumba12, tumba13],
    category: 'Приліжкові тумби',
    categoryRu: 'Nightstands',
    features: [
      'Каркас: Метал',
      'Матеріал: ДСП вищої категорії',
      'Розміри: Ш 531 х В 474 х Г411',
      'Колір: "Бiлий перл", "Глиняний сiрий", "Кобальт", "Шиншила", "Кашемiр"'
    ]
  },
  {
    id: 42,
    name: 'Комод "Орлеан"',
    //price: 'вiд 10 575₴',
    img: komod1,
    additionalImages: [komod2, komod3, komod4, komod5, komod6, komod7, komod8, komod9, komod10],
    category: 'Комоди',
    categoryRu: 'Dressers',
    features: [
      'Каркас: Метал',
      'Матеріал: ДСП вищої категорії',
      'Розміри: Ш 952 х В 831 х Г506',
      'Колір: "Бiлий перл", "Глиняний сiрий", "Кобальт", "Шиншила", "Кашемiр"'
    ]
  },
  {
    id: 45,
    name: 'Шафа 3-дв "Орлеан"',
    //price: 'вiд 17 570₴',
    img: shafa1,
    additionalImages: [shafa2, shafa3],
    category: 'Шафи',
    categoryRu: 'Wardrobes',
    features: [
      'Каркас: Метал',
      'Матеріал: ДСП вищої категорії',
      'Розміри: Ш 1400 х В 2104 х Г560',
      'Колір: "Глиняний сiрий", "Кобальт", "Бiлий перл"'
    ]
  },


  
  
];

export default orleanProducts;
