import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './FAQ.css';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const faqItemRef = useRef(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (faqItemRef.current) {
      observer.observe(faqItemRef.current);
    }

    return () => {
      if (faqItemRef.current) {
        observer.unobserve(faqItemRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={faqItemRef}
      className={`faq-item fade-in ${isVisible ? 'visible' : ''}`}
    >
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        <span>{isOpen ? '-' : '+'}</span>
        {question}
      </div>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
        <div className="faq-answer-content">{answer}</div>
      </div>
    </div>
  );
};

export default function FAQ() {
  const { t } = useTranslation();
  const faqData = t('faqData', { returnObjects: true });

  return (
    <div className="faq">
      <h1>{t('faqTitle')}</h1>
      {faqData.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
  );
}
