import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './About.css';

export default function About() {
  const { t } = useTranslation();
  const aboutRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (aboutRef.current) {
      const rect = aboutRef.current.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <main>
      <div
        ref={aboutRef}
        className={`about ${isVisible ? 'fade-in' : ''}`}
      >
        <h1>{t('aboutTitle')}</h1>
        <p>{t('aboutParagraph1')}</p>
        <br />
        <p>
          {t('aboutParagraph2')}
          {t('aboutList', { returnObjects: true }).map((item, index) => (
            <React.Fragment key={index}>
              <br />⁃ {item}
            </React.Fragment>
          ))}
        </p>
      </div>
    </main>
  );
}
